<template>
  <div>架构图</div>
</template>

<script>
export default {
  name: 'remarkLogin'
}
</script>

<style scoped>

</style>